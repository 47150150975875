import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthSystemUser, BaseJwt, Token } from '@dto/User';
import { ACL, parseACL, Permission } from '@utils/permission.util';

export interface IAuthResponse {
  success: boolean;
  errorCode?: number;
  token?: string;
}

// define pseudo-storage for devices that don't support localStorage
if (!window.localStorage) {
  const dict = {} as any; // @ts-ignore
  window['localStorage'] = {
    getItem: (key: string) => dict[key],
    setItem: (key: string, value: string) => (dict[key] = value),
    removeItem: (key: string) => (delete dict[key])
  };
}

const REGULAR_TOKEN_KEY = 'token';
const EXCHANGE_TOKENS_KEY = 'exchange';

@Injectable({ providedIn: 'root' })
export class AuthService {

  private _token?: string | null;
  orgId?: string | null;
  formId?: string | null;
  static _user?: AuthSystemUser;
  private static acl?: ACL;

  get user(): AuthSystemUser | undefined {
    return AuthService._user;
  }

  set user(u: AuthSystemUser | undefined) {
    AuthService._user = u;
    if (u?.acl) {
      console.log("parcing acl for: ", u.acl);
      AuthService.acl = parseACL(u.acl);
      console.log("result: ", AuthService.acl);
    }
  }

  static isAllowed(p: Permission): boolean {
    return AuthService.acl?.[p]!!;
  }

  constructor(private http: HttpClient) {
    this._token = localStorage.getItem(REGULAR_TOKEN_KEY);
    if (this.isJwt(this._token)) {
      this.user = this.decodeJwt(this._token!);
    }
  }

  set token(token: string) {
    this._token = token;
    localStorage.setItem(REGULAR_TOKEN_KEY, token);
  }

  get token(): string {
    return this._token ?? localStorage.getItem(REGULAR_TOKEN_KEY) ?? '';
  }

  getAuthorizedUsers(): Array<AuthSystemUser & Token> {
    const tokens = JSON.parse(localStorage.getItem(EXCHANGE_TOKENS_KEY) || "[]") as string[];
    return tokens.map(t => this.decodeJwt(t));
  }

  private saveExchangeToken(token: string): void {
    const users = this.getAuthorizedUsers();
    const newUser = this.decodeJwt(token);
    for (let i = 0; i < users.length; i++) {
      if (users[i].id === newUser.id) {
        users.splice(i--, 1);
      }
    }
    const tokens = [...users.map(u => u.token), token];
    localStorage.setItem(EXCHANGE_TOKENS_KEY, JSON.stringify(tokens));
  }

  private isJwt(t?: string | null): boolean {
    return !!t?.length && t.includes(".") && t.split('.').length === 3;
}

  private decodeJwt(token: string): AuthSystemUser & Token & BaseJwt {
    let payload = token
      .split('.')[1]
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    payload = window.atob(payload)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')

    payload = decodeURIComponent(payload);

    const user = JSON.parse(payload) as AuthSystemUser & BaseJwt;

    return { ...user, token };
  }


  signIn(email: string, password: string): Promise<IAuthResponse> {
    const request = this.http.post<Token>('auth', { email, password });
    return this.handleAuthResponse(request, true);
  }

  async exchangeToken(token: string): Promise<IAuthResponse> {
    this.token = token;
    const request = this.http.post<Token>('auth/exchange', {});
    return await this.handleAuthResponse(request);
  }

  async isTokenValid(): Promise<IAuthResponse> {
    try {
      const t = this.token;
      if (this.isJwt(t)) {
        const decoded = this.decodeJwt(t);
        return {
          success: (decoded.exp || 0) >= new Date().getTime() / 1000
        }
      }
    } catch (ignore) {}

    return {
      success: false,
      errorCode: 401,
    }
    // const request = this.http.post<Token>('auth/refresh', {})
    // return await this.handleAuthResponse(request);
  }

  private async handleAuthResponse(request: Observable<Token>, saveAsExchangeToken?: boolean): Promise<IAuthResponse> {
    return new Promise<IAuthResponse>(resolve => {
      const subscription = request.subscribe({
        next: (response: Token) => {
          subscription.unsubscribe();
          this.token = response.token;
          if (saveAsExchangeToken) {
            this.saveExchangeToken(response.token);
            this.exchangeToken(response.token).then(resolve);
          } else {
            this.user = this.decodeJwt(response.token);
            resolve({ success: true });
          }
        },
        error: (error) => {
          resolve({
            success: false,
            errorCode: error.status
          });
        }
      });
    });
  }

  logout(): void {
    delete this._token;
    localStorage.removeItem(REGULAR_TOKEN_KEY);
    document.location.href = '/sign-in';
  }
}
